import qs from 'query-string';
import config from '../config';

const DEFAULT_FETCH_OPTIONS = {
  credentials: 'include'
};

const PARSERS = {
  'application/json': async response => {
    return response.json();
  }
}

class AuthGatewayApi {

  async get(path, options) {
    return this.request('GET', path, null, options);
  }

  async post(path, body, options) {
    return this.request('POST', path, body, options);
  }

  async put(path, body, options) {
    return this.request('PUT', path, body, options);
  }

  async request(method, path, body, {rawResponse = false, params, ...otherOptions} = {}) {
    let error;

    try {
      let url = `${config.auth.serverUrl}${path}`;

      if (params) {
        url += `?${qs.stringify(params)}`;
      }

      const fetchOptions = {
        ...DEFAULT_FETCH_OPTIONS,
        ...otherOptions,
        method,
        body
      };
  
      const response = await fetch(url, fetchOptions);
  
      if (rawResponse) {
        return response;
      }
  
      const parserFunction = PARSERS[response.headers.get('content-type')];
      let data = null;

      if (parserFunction) {
        try {
          data = await parserFunction(response);
        } catch(e) {
          console.error(e);
        }
      }
  
      if (response.ok) {
        return data;
      }

      if (data?.error_description) {
        error = new Error(data.error_description);
        error.isBusiness = true;
        error.data = data;
      } else {
        error = new Error(`Error - GET ${url}: ${response.status} (${response.statusText})`);
      }
    } catch (e) {
      console.error(e);
      error = e;
    }

    throw error;
  }

}

export default new AuthGatewayApi();
