export function hasStateChanged(oldState, newState) {
    return Object.keys(newState).some(key => {
        const newValue = newState[key];
        const oldValue = oldState[key];

        if (!newValue && !oldValue)
            return false;

        return newValue !== oldValue;
    });
}

export function isNotEmpty(object) {
    return object && Object.values(object)
        .some(x => x);
}

export function isEmpty(object) {
    return !isNotEmpty(object)
}