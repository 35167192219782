import React from 'react';
import LoadingPanel from './LoadingPanel';
import AuthGatewayService from '../services/AuthGatewayService';
import CognitoService from '../services/CognitoService';

export default ({ user, requestState }) => {
  const { rememberUser } = user;
  const idToken = user.cognitoUserSession?.idToken?.jwtToken || '';

  CognitoService.clearAuthenticationData();
  AuthGatewayService.processSignInResponse(idToken, rememberUser, requestState);
  return <LoadingPanel />;
};
