import React from 'react';
import { withRouter, Route } from 'react-router-dom';
import LoadingPanel from './LoadingPanel';

class Path extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      isReady: false
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    if (this.props.configureApplication)
      await this.props.configureApplication(this.props.location.search);

    if (this._isMounted) this.setState({ isReady: true });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { children } = this.props;
    let uiContent = null;

    if (this.state.isReady) {
      uiContent = children;
    } else {
      uiContent = (
        <LoadingPanel />
      );
    }

    return <Route {...this.props} children={uiContent} />;
  }
}

export default withRouter(Path);
