import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {FormSection, Button} from './Utils';

function ExternalIdpSignIn(props) {
  const {externalIdp} = props;

  const handleExternalIdpRedirect = useCallback(() => {
    window.location = externalIdp.signInUrl;
  }, [externalIdp]);

  return (
    <FormSection>
      <Button className="signInSsoButton" onClick={handleExternalIdpRedirect}>
        Log in with SSO
      </Button>
    </FormSection>
  )
}

ExternalIdpSignIn.propTypes = {
  externalIdp: PropTypes.object.isRequired
};

export default ExternalIdpSignIn;
