import * as React from 'react';
import { I18n } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import LoadingPanel from './LoadingPanel';
import { Alert, Button, FormField, FormSection, Input, InputLabel, Link, SectionBody, SectionFooter, SectionFooterPrimaryContent, SectionHeader } from './Utils';
import FormComponent from './FormComponent'
import CognitoService from '../services/CognitoService';
import { maskEmail } from '../util/string'
import { validateNotEmpty } from '../util/validation';

class EmailVerification extends FormComponent {

    constructor(props) {
        super(props, initialState, validators);
    }

    componentDidMount() {
        this.sendVerificationCode();
    }

    sendVerificationCode = async () => {
        this.setState({ loading: true })
        this.clearErrors();

        try {
            await CognitoService.verifyCurrentUserEmail();
            this.setState({ loading: false });
        } catch (err) {
            const errorMessage = CognitoService.extractErrorMessage(err);
            this.setGlobalError(errorMessage);
        }
    }

    submit = async () => {
        try {
            await CognitoService.verifyCurrentUserEmailSubmit(this.state.code);
            const user = await CognitoService.currentAuthenticatedUser({ bypassCache: true });
            this.props.onUserChange(user, () => this.goTo('/signIn'));
        } catch (err) {
            const errorMessage = CognitoService.extractErrorMessage(err);
            this.setGlobalError(errorMessage);
        }
    }

    render() {
        const { loading, code, formErrors } = this.state
        const { user } = this.props

        return (
            <>
                {loading && <LoadingPanel />}
                {!loading && (
                    <FormSection>
                        <SectionHeader>{I18n.get('Verify your email')}</SectionHeader>
                        {getCodeDeliveryInfo(user)}
                        <form onSubmit={this.handleSubmit}>
                            <SectionBody>
                                <FormField error={formErrors.code} hint={this.buildCodeHint()}>
                                    <InputLabel>{I18n.get('Code')} *</InputLabel>
                                    <Input
                                        name="code"
                                        key="code"
                                        type="text"
                                        autoComplete="off"
                                        value={code}
                                        onChange={this.handleInputChange}
                                        autoFocus={true} />
                                </FormField>
                            </SectionBody>

                            {formErrors._global && <Alert severity="error" title="Error" description={formErrors._global} />}

                            <SectionFooter>
                                <SectionFooterPrimaryContent>
                                    <Link onClick={this.goBackToSignIn}>{I18n.get('Back to Log In')}</Link>
                                </SectionFooterPrimaryContent>
                                <SectionFooterPrimaryContent>
                                    <Button type="submit">{I18n.get('Submit')}</Button>
                                </SectionFooterPrimaryContent>
                            </SectionFooter>
                        </form>
                    </FormSection>
                )}
            </>
        )
    }

    buildCodeHint = () =>
        <Link onClick={this.sendVerificationCode}>{I18n.get('Resend code')}</Link>

}

const initialState = { code: '' };
const validators = { code: validateNotEmpty }

const getCodeDeliveryInfo = user => {
    const email = user?.attributes?.email

    if (email) {
        const message = `A code was sent to ${maskEmail(email)}. Please, fetch that code and enter it in the following form in order to verify your email.`
        return <Alert severity="info" title="Code sent" description={message} block />
    }
    return null;
}

export default withRouter(EmailVerification);