import React from "react";
import { FormSection, SectionHeader, SectionBody, Tile } from "./Utils";
import LoadingPanel from "./LoadingPanel";
import BaseComponent from "./BaseComponent";
import Error from "./Error";
import AuthGatewayService from "../services/AuthGatewayService";
import Avatar from "react-avatar";

class SelectTenant extends BaseComponent {

  constructor(props) {
    super(props, initialState);
  }

  async componentDidMount() {
    try {
      const requestId = this.props.requestState;
      const tenants = await AuthGatewayService.getUserTenants(requestId);
      this.setState({ tenants, loading: false });
    } catch (err) {
      this.setState({ error: err.message, loading: false });
    }
  }

  select = async (tenant) => {
    const requestId = this.props.requestState;

    this.setState({ loading: true });
    AuthGatewayService.selectTenant(tenant.id, requestId);
  };

  render() {
    const { error, loading, tenants } = this.state;

    return (
      <>
        {error && <Error error={error} />}
        {!error && loading && <LoadingPanel />}
        {!error && !loading && (
          <FormSection>
            <SectionHeader>Choose an Organization</SectionHeader>
            <form onSubmit={this.handleSubmit}>
              <SectionBody>
                <ul className="tile-list">
                  {tenants.map((tenant, idx) =>
                    <li key={idx}>
                      <Tile
                        image={buildAvatar(tenant)}
                        title={tenant.name}
                        enabled={tenant.enabled}
                        onclick={() => this.select(tenant)} />
                    </li>
                  )}
                </ul>
              </SectionBody>
            </form>
          </FormSection>
        )}
      </>
    );
  }
}

const initialState = {
  error: null,
  loading: true,
  tenants: []
}

const buildAvatar = tenant =>
  <Avatar
    name={tenant.name}
    round={true}
    maxInitials={2}
    size="35"
  />


export default SelectTenant;
