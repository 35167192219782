import React from 'react';
import { Alert, FormSection } from './Utils';

const Error = ({ error }) => {
  return (
    <FormSection>
      <Alert severity="warn" title="Invalid request" description={error} block>
        <br />
        <br />
        <strong>Contact your administrator</strong> and try again.
      </Alert>
    </FormSection>
  );
};

export default Error;
