import config from '../config';

let initialSitePath = null;

export const appPath = process.env.PUBLIC_URL.replace(/^\//, '');

export function storeSitePath(path) {
  initialSitePath = path;
}

export function getSitePath() {
  return initialSitePath;
}

export function getBasePath(sitePath = initialSitePath) {
  if (!sitePath) {
    return `/${appPath}`;
  }

  return `/${sitePath}/${appPath}`;
}

export function redirectLandingApp() {
  const landingAppUrl = new URL(config.landing.appUrl);
  const sitePath = getSitePath();

  if (sitePath) {
    landingAppUrl.pathname = `/${sitePath}${landingAppUrl.pathname}`;
  }

  setTimeout(() => {
    window.location = landingAppUrl;
  }, 1000);
}
