import qs from 'query-string';
import config from '../config';
import { getSitePath, getBasePath } from '../route/routing';
import { forever } from '../util/promise';
import authGatewayApi from './AuthGatewayApi';

class AuthGatewayService {

  processSignInResponse = async (idToken, rememberUser = false, state) => {
    let url = `${config.auth.serverUrl}/callback?id_token=${idToken}&remember_user=${rememberUser}&state=${state}`;

    const sitePath = getSitePath();

    if (sitePath) {
      url += `&tenant_site_path=${sitePath}`;
    }

    window.location = url;
  }

  getExternalIdp = async (requestId) => {
    try {
      return await authGatewayApi.get(`/requests/${requestId}/external-idp`, {
        params: {
          site_path: getSitePath()
        }
      });
    } catch (error) {
      return null;
    }
  }

  getUserTenants = async (requestId) => {
    try {
      return await authGatewayApi.get(`/login/${requestId}/tenants`);
    } catch (error) {
      if (error.isBusiness) {
        throw error;
      }

      throw new Error('Error trying to fetch your tenants');
    }
  }

  selectTenant = async (tenantId, requestId) => {
    window.location = `${config.auth.serverUrl}/login/${requestId}/tenants/${tenantId}`;
  }

  setTenantSitePath = async (sitePath, requestId) => {
    try {
      const response = await authGatewayApi.put(`/requests/${requestId}/tenant`, null, {
        params: {
          site_path: sitePath
        }
      });

      setTimeout(() => {
        const signInLocation = new URL(window.location);

        const params = {
          ...qs.parse(signInLocation.search),
          state: response.id
        };

        signInLocation.pathname = `${getBasePath(sitePath)}/signIn`;
        signInLocation.search = `?${qs.stringify(params)}`;

        window.location = signInLocation;
      }, 1000)

      return forever();
    } catch (error) {
      if (!error.isBusiness) {
        throw new Error('Error trying to find your organization');
      }

      if (error.message.includes('Tenant not found for the site path')) {
        throw new Error(`The URL you entered does not exist. If you can't remember your organization's VectorSolutions URL, reach out to your administrator.`);
      }

      throw error;
    }
  }

  fetchUserTenantsByEmail = async (email, requestId) => {
    try {
      await authGatewayApi.post(`/requests/${requestId}/user/tenants`, null, {
        params: {
          email
        }
      });
    } catch (error) {
      if (error.isBusiness) {
        throw error;
      }

      throw new Error('Something went wrong trying to find your organizations.');
    }
  }
}

export default new AuthGatewayService();
