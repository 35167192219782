import * as React from "react";
import { JS } from "aws-amplify";
import vectorSolutionsLogo from "../assets/VectorSolutionsLogo.png";

export const Container = ({ children, style }) => {
  return (
    <div className="container" style={style}>
      {children}
    </div>
  );
};

export const FormContainer = ({ children, style }) => {
  return (
    <div className="formContainer" style={style}>
      {children}
    </div>
  );
};

export const FormSection = ({ children, style }) => {
  return (
    <FormContainer style={style}>
      <img
        className="brandLogo"
        src={vectorSolutionsLogo}
        alt="Vector Solutions logo"
      />

      <div className="formSection" style={style}>
        {children}
      </div>
    </FormContainer>
  );
};

export const SectionHeader = ({ children, hint, style }) => {
  return (
    <div className="sectionHeader" style={style}>
      <SectionHeaderContent>
        {children}
        {hint && <div className="sectionHeaderHint">{hint}</div>}
      </SectionHeaderContent>
    </div>
  );
};

export const SectionHeaderContent = ({ children, style }) => {
  return (
    <span className="sectionHeaderContent" style={style}>
      {children}
    </span>
  );
};

export const SectionFooter = ({ children, style }) => {
  return (
    <div className="sectionFooter" style={style}>
      {children}
    </div>
  );
};

export const SectionFooterPrimaryContent = ({ children, style }) => {
  return (
    <span className="sectionFooterPrimaryContent" style={style}>
      {children}
    </span>
  );
};

export const SectionFooterSecondaryContent = ({ children, style }) => {
  return (
    <span className="sectionFooterSecondaryContent" style={style}>
      {children}
    </span>
  );
};

export const SectionBody = ({ children, style }) => {
  return (
    <div className="sectionBody" style={style}>
      {children}
    </div>
  );
};

export const FormField = ({ error, children, hint, className, style }) => {
  return (
    <div className={`formField ${error ? 'error' : ''} ${className}`} style={style}>
      {children}
      <div className="input-footer">
        <div className="error-message">{error}</div>
        <div className="hint">{hint}</div>
      </div>
    </div>
  );
};

export const Tile = ({ image, title, enabled, onclick }) => {
  return (
    <div
      href="#"
      onClick={enabled ? onclick : undefined}
      className={`tile ${!enabled ? "disabled" : ""}`}
    >
      <div className="tile-content">
        <div className="tile-image" aria-hidden="true">
          {image}
        </div>
        <div className="tile-text">
          <div className="tile-title">{title}</div>
          {!enabled && <div className="tile-subtitle">Disabled</div>}
        </div>
      </div>
    </div>
  );
};

export const Input = (props) => {
  const { style, disabled, error, startAddornment, ...rest } = props;

  let inputClasses = ['input'];

  if (startAddornment) {
    inputClasses.push('start-addorned');
  }

  return (
    <div className={`input-container ${disabled ? 'disabled' : ''}`}>
      {startAddornment && (
        <div className="input-addornment input-addornment-start">
          {startAddornment}
        </div>
      )}

      <input
        {...rest}
        disabled={disabled}
        className={`input ${startAddornment ? 'start-addorned' : ''}`}
        style={style}
      />
    </div>
  );
};

export const Checkbox = ({ name, label, ...otherProperties }) => {
  return (
    <div>
      <input type="checkbox" name={name} id={name} {...otherProperties} />
      <label htmlFor={name}>{label}</label>
    </div>
  );
};

export const Button = (props) => {
  const { children, disabled, className } = props;
  const p = JS.objectLessAttributes(props, "error");
  return (
    <button {...p} className={`button ${className}`} disabled={disabled}>
      {children}
    </button>
  );
};

export const SignInButton = (props) => {
  const { children, style } = props;
  const p = JS.objectLessAttributes(props, "error");
  return (
    <button {...p} className="signInButton" style={style}>
      {children}
    </button>
  );
};

export const SignInButtonIcon = ({ children, style }) => {
  return (
    <span className="signInButtonIcon" style={style}>
      {children}
    </span>
  );
};

export const SignInButtonContent = ({ children, style }) => {
  return (
    <span className="signInButtonContent" style={style}>
      {children}
    </span>
  );
};

export const Link = (props) => {
  const p = JS.objectLessAttributes(props, "error");
  return (
    <a {...p} className="a" style={props.style}>
      {props.children}
    </a>
  );
};

export const Label = (props) => {
  const { children, style } = props;
  const p = JS.objectLessAttributes(props, "error");
  return (
    <label {...p} className="label" style={style}>
      {children}
    </label>
  );
};

export const Hint = (props) => {
  const { children, style } = props;
  return (
    <div className="hint" style={style}>
      {children}
    </div>
  );
};

export const InputLabel = ({ children, style }) => {
  return (
    <div className="inputLabel" style={style}>
      {children}
    </div>
  );
};

export const Spinner = ({ show }) => {
  return show ? (
    <div className="backdrop">
      <div className="modal">
        <div className="lds-dual-ring"></div>
      </div>
    </div>
  ) : null;
};

export const Alert = (props) => {
  const { severity, title, description, block, onClose, children } = props;
  return (
    <div
      className={`alert alert-${severity} ${block ? "alert-block" : null}`}
      style={!onClose && { padding: 14 }}
    >
      {onClose && (
        <span className="close" data-dismiss="alert" onClick={onClose}>
          x
        </span>
      )}

      {block ? (
        <h4 className="alert-heading">{title}. </h4>
      ) : (
        <strong>{title}. </strong>
      )}
      {description}
      {children}
    </div>
  );
};
