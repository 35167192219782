import React from 'react';
import { Switch } from 'react-router-dom';
import queryStringParser from 'query-string';
import TenantAwareRouter from './route/TenantAwareRouter';
import config from './config';
import SignIn from './components/SignIn';
import RequireNewPassword from './components/RequireNewPassword';
import PasswordReset from './components/PasswordReset';
import TenantLookup from './components/TenantLookup';
import EmailTenantLookup from './components/EmailTenantLookup';
import LoggedIn from './components/LoggedIn';
import SelectTenant from './components/SelectTenant';
import LoadingPanel from './components/LoadingPanel';
import BaseComponent from './components/BaseComponent';
import Path from './components/Path';
import ProtectedPath from './components/ProtectedPath';
import Error from './components/Error';
import { getSitePath, redirectLandingApp } from './route/routing';
import { hasStateChanged } from './util/object'
import authGatewayService from './services/AuthGatewayService';
import featureFlagService from './services/FeatureFlagService';
import CognitoService from './services/CognitoService'
import EmailVerification from './components/EmailVerification';
import { enteredHisCredentials, isEmailVerified } from './util/user';

class App extends BaseComponent {

  constructor(props) {
    super(props, initialState);
  }

  async componentDidMount() {
    await CognitoService.configure(config.userpool.id, config.userpool.clientId);
    await featureFlagService.initialize()
    
    this.setState({ loading: false });
  }

  configureApplication = async (queryString) => {
    const queryStringParams = queryStringParser.parse(queryString);
    const { client, state: requestState, error, error_description } = queryStringParams;
    const sitePath = getSitePath();

    if (error || error_description)
      this.setAppError(error, error_description)
    else if (!client || !requestState)
      this.handleMissingParams();
    else {
      const newState = { client, requestState, sitePath };
      const stateHasChanged = hasStateChanged(this.state, newState);

      if (stateHasChanged)
        newState.externalIdp = await authGatewayService.getExternalIdp(requestState);

      this.setState({ ...newState, appError: null, loading: false });
    }
  };

  onUserChange = async (user, callback) => {
    if (user?.signInUserSession) {
      const { signInUserSession } = user;
      delete user.signInUserSession;
      user.cognitoUserSession = signInUserSession;
    }

    this.setState({ user }, callback);
  };

  setAppError = (errorCode, errorDescription) => {
    const appError = errorDescription || errorCode
    this.setState({ ...initialState, appError, loading: false });
  }

  handleMissingParams = () =>
    this.setState({ loading: true }, () => redirectLandingApp());

  render() {
    const { user, loading, appError } = this.state;
    const userEnteredHisCredentials = enteredHisCredentials(user);
    const userIsLoggedIn = user && user.loggedIn;
    const emailVerified = user && isEmailVerified(user);
    const isAllowedToVerifyEmail = userEnteredHisCredentials && !userIsLoggedIn && !emailVerified
    const isAllowedToRequireNewPassword = userEnteredHisCredentials && !userIsLoggedIn && user.challengeName === 'NEW_PASSWORD_REQUIRED';

    return (
      <>
        {appError && <Error error={appError} />}
        {!appError && loading && <LoadingPanel />}
        {!appError && !loading && (
          <TenantAwareRouter>
            <Switch>
              <ProtectedPath exact path="/" isAllowed={false}></ProtectedPath>

              <Path
                exact
                path="/signIn"
                configureApplication={this.configureApplication}
              >
                <SignIn
                  client={this.state.client}
                  sitePath={this.state.sitePath}
                  externalIdp={this.state.externalIdp}
                  user={user}
                  onUserChange={this.onUserChange}
                />
              </Path>

              <Path
                exact
                path="/tenantLookup"
                configureApplication={this.configureApplication}
              >
                <TenantLookup
                  requestState={this.state.requestState}
                />
              </Path>

              <Path
                exact
                path="/emailTenantLookup"
                configureApplication={this.configureApplication}
              >
                <EmailTenantLookup
                  requestState={this.state.requestState}
                />
              </Path>

              <Path
                exact
                path="/passwordReset"
                configureApplication={this.configureApplication}
              >
                <PasswordReset user={user} />
              </Path>

              <ProtectedPath
                exact
                path="/verifyEmail"
                isAllowed={isAllowedToVerifyEmail}
                configureApplication={this.configureApplication}
              >
                <EmailVerification user={user} onUserChange={this.onUserChange} />
              </ProtectedPath>

              <ProtectedPath
                exact
                path="/requireNewPassword"
                isAllowed={isAllowedToRequireNewPassword}
                configureApplication={this.configureApplication}
              >
                <RequireNewPassword
                  user={user}
                  onUserChange={this.onUserChange}
                />
              </ProtectedPath>

              <ProtectedPath
                exact
                path="/loggedIn"
                isAllowed={userIsLoggedIn}
                configureApplication={this.configureApplication}
              >
                <LoggedIn {...this.state} onUserChange={this.onUserChange} />
              </ProtectedPath>

              <Path
                exact
                path="/selectTenant"
                configureApplication={this.configureApplication}
              >
                <SelectTenant
                  user={user}
                  requestState={this.state.requestState}
                  onUserChange={this.onUserChange}
                />
              </Path>
            </Switch>
          </TenantAwareRouter>
        )}
      </>
    );
  }
}

const initialState = {
  client: null,
  requestState: null,
  sitePath: null,
  externalIdp: null,
  user: null,
  appError: null,
  loading: true
};

export default App;
