import React from 'react';
import PropTypes from 'prop-types';
import ExternalIdpSignIn from './ExternalIdpSignIn';
import VectorIdpSignIn from './VectorIdpSignIn';

function NewSignIn(props) {
  const {externalIdp, ...otherProps} = props;

  if (externalIdp) {
    return <ExternalIdpSignIn externalIdp={externalIdp} />
  }

  return <VectorIdpSignIn {...otherProps} />
}

NewSignIn.propTypes = {
  client: PropTypes.string,
  externalIdp: PropTypes.object,
  user: PropTypes.object,
  onUserChange: PropTypes.func
};

NewSignIn.defaultProps  = {
  onUserChange: () => {}
};

export default NewSignIn;
