import * as React from 'react';
import { I18n } from 'aws-amplify';
import { FormSection, SectionHeader } from './Utils';
import { withRouter } from 'react-router-dom';
import BaseComponent from './BaseComponent';
import FormComponent from './FormComponent';
import { SectionBody, SectionFooter, SectionFooterPrimaryContent, Button, FormField, InputLabel, Input, Link, Alert } from './Utils';
import LoadingPanel from './LoadingPanel';
import { validateNotEmpty } from '../util/validation'
import CognitoService from '../services/CognitoService';

class PasswordReset extends BaseComponent {

  constructor(props) {
    let initialState = { username: props.user?.username || '', codeSent: false, codeDeliveryDetails: null }
    super(props, initialState);
  }

  sendCode = async username => {
    const { CodeDeliveryDetails: codeDeliveryDetails } = await CognitoService.forgotPassword(username);
    this.setState({ username, codeDeliveryDetails, codeSent: true });
  };

  resendCode = () =>
    this.sendCode(this.state.username);

  submitCode = async (username, code, password) => {
    await CognitoService.forgotPasswordSubmit(username, code, password);
    this.goBackToSignIn();
  }

  render() {
    const { codeSent, username, codeDeliveryDetails } = this.state;
    const { passwordExpired } = this.props.user || {};

    return <div>
      {!codeSent
        ? (
          <UsernameForm
            username={username}
            sendCode={this.sendCode}
            history={this.props.history}
            location={this.props.location} />)
        : (
          <CodePasswordForm
            codeDeliveryDetails={codeDeliveryDetails}
            username={username}
            passwordExpired={passwordExpired}
            submitCode={this.submitCode}
            resendCode={this.resendCode}
            history={this.props.history}
            location={this.props.location} />)
      }
    </div>
  }
}

class UsernameForm extends FormComponent {

  constructor(props) {
    let initialState = { username: props.username || '' }
    let validators = { username: validateNotEmpty }
    super(props, initialState, validators);
  }

  componentDidMount() {
    if (this.state.username)
      this.setState({ loading: true }, this.submit)
  }

  submit = async () => {
    try {
      await this.props.sendCode(this.state.username);
    } catch (err) {
      const errorMessage = CognitoService.extractErrorMessage(err);
      this.setGlobalError(errorMessage);
    }
  }

  render() {
    const { username, formErrors, loading } = this.state;

    if (loading)
      return <LoadingPanel />

    return (
      <FormSection>
        <SectionHeader>{I18n.get('Reset your password')}</SectionHeader>
        <form onSubmit={this.handleSubmit}>
          <SectionBody>
            <FormField error={formErrors.username}>
              <InputLabel>{I18n.get('Username')} *</InputLabel>
              <Input
                name="username"
                key="username"
                autoComplete="username"
                type="text"
                value={username}
                onChange={this.handleInputChange}
                autoFocus={true}
              />
            </FormField>
          </SectionBody>

          {formErrors._global && <Alert severity="error" title="Error" description={formErrors._global} />}

          <SectionFooter>
            <SectionFooterPrimaryContent>
              <Link onClick={this.goBackToSignIn}>{I18n.get('Back to Log In')}</Link>
            </SectionFooterPrimaryContent>
            <SectionFooterPrimaryContent>
              <Button type="submit">{I18n.get('Send Code')}</Button>
            </SectionFooterPrimaryContent>
          </SectionFooter>
        </form>
      </FormSection>
    )
  }
}

class CodePasswordForm extends FormComponent {

  constructor(props) {
    let initialState = { code: '', password: '' };
    let validators = { code: validateNotEmpty, password: validateNotEmpty };
    super(props, initialState, validators);
  }

  submit = async () => {
    const { username } = this.props;
    const { code, password } = this.state;

    try {
      await this.props.submitCode(username, code, password);
    } catch (err) {
      const errorMessage = CognitoService.extractErrorMessage(err);
      this.setGlobalError(errorMessage);
    }
  };

  resendCode = async () => {
    this.setState({ loading: true });
    this.clearErrors();

    try {
      await this.props.resendCode();
      this.setState({ loading: false });
    } catch (err) {
      const errorMessage = CognitoService.extractErrorMessage(err);
      this.setGlobalError(errorMessage);
    }
  }

  render() {
    const { loading, code, password, formErrors } = this.state
    const { codeDeliveryDetails, passwordExpired } = this.props

    if (loading)
      return <LoadingPanel />

    return (
      <FormSection>
        <SectionHeader>{I18n.get('Reset your password')}</SectionHeader>
        { getCodeDeliveryInfo(codeDeliveryDetails, passwordExpired)}
        <form onSubmit={this.handleSubmit}>
          <SectionBody>
            <FormField error={formErrors.code} hint={this.buildCodeHint()}>
              <InputLabel>{I18n.get('Code')} *</InputLabel>
              <Input
                name="code"
                key="code"
                autoComplete="off"
                type="text"
                value={code}
                onChange={this.handleInputChange}
                autoFocus={true}
              />
            </FormField>
            <FormField error={formErrors.password}>
              <InputLabel>{I18n.get('New Password')} *</InputLabel>
              <Input
                name="password"
                key="password"
                type="password"
                value={password}
                onChange={this.handleInputChange}
              />
            </FormField>
          </SectionBody>

          {formErrors._global && <Alert severity="error" title="Error" description={formErrors._global} />}

          <SectionFooter>
            <SectionFooterPrimaryContent>
              <Link onClick={this.goBackToSignIn}>{I18n.get('Back to Log In')}</Link>
            </SectionFooterPrimaryContent>
            <SectionFooterPrimaryContent>
              <Button type="submit">{I18n.get('Submit')}</Button>
            </SectionFooterPrimaryContent>
          </SectionFooter>
        </form>
      </FormSection>
    )
  }

  buildCodeHint = () =>
    <Link onClick={this.resendCode}>{I18n.get('Resend code')}</Link>
}

const getCodeDeliveryInfo = (codeDeliveryDetails, passwordExpired) => {
  let message = passwordExpired ? 'To continue, you must set a new password for your account by following next instructions. ' : '';
  message += `A code was sent to ${codeDeliveryDetails.Destination}. Please, fetch that code and enter it in the following form. Next, provide a new password for your account.`

  return <Alert
    severity="info"
    title={passwordExpired ? 'Your password has expired' : 'Code sent'}
    description={message}
    block />;
};

export default withRouter(PasswordReset);