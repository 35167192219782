import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {BrowserRouter, Switch, Route, Redirect, useParams, useLocation} from 'react-router-dom';
import {appPath, storeSitePath, getBasePath} from './routing';

function BasePathRouter(props) {
  const {router: Router = BrowserRouter, children} = props;
  const location = useLocation();
  const {sitePath, appParam} = useParams();

  const basePath = useMemo(() => {
    storeSitePath(sitePath);
    return getBasePath(sitePath);
  }, [sitePath]);

  const missingAppPath = useMemo(() => {
    return appParam !== appPath
  }, [appParam]);

  if (missingAppPath) {
    return (
      <Redirect
        to={{
          ...location,
          pathname: getBasePath(sitePath || appParam)
        }}
      />
    );
  }

  return (
    <Router basename={basePath}>
      {children}
    </Router>
  );
}

export default function TenantAwareRouter(props) {
  const {router, children} = props;

  const renderBasePathRouter = useCallback(() => {
    return (
      <BasePathRouter router={router}>
        {children}
      </BasePathRouter>
    );
  }, [router, children]);

  return (
    <BrowserRouter>
      <Switch>
        <Route
          path={[
            `/:appParam(${appPath})`,
            '/:sitePath/:appParam',
            '/:sitePath',
          ]}
          render={renderBasePathRouter}
        />

        <Route path="/">
          <Redirect to={`/${appPath}`} />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

TenantAwareRouter.propTypes = {
  router: PropTypes.elementType,
  children: PropTypes.node
};

TenantAwareRouter.defaultProps = {
  router: BrowserRouter
};
