const EMAIL_REGEX = /^[a-zA-Z0-9_!#$%&’*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$/;

export const validateNotEmpty = (fieldName, fieldValue) =>
  !fieldValue ? `Enter your ${fieldName}` : null;

export function checkInvalidEmail(email) {
  if (!email) {
    return 'Enter your email address.';
  }

  if (!EMAIL_REGEX.test(email)) {
    return 'Enter a valid email address.';
  }

  return false;
}
