import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import LoadingPanel from './LoadingPanel';

class ProtectedPath extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      isReady: false
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    if (this.props.configureApplication)
      await this.props.configureApplication(this.props.location.search);

    if (this._isMounted) this.setState({ isReady: true });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let uiContent = null;
    const { isAllowed, location } = this.props;

    if (this.state.isReady) {
      uiContent = isAllowed ? (
        <Route {...this.props} />
      ) : (
        <Redirect to={`/signIn${location.search}`} />
      );
    } else {
      uiContent = (
        <Route {...this.props}>
          <LoadingPanel />
        </Route>
      );
    }

    return uiContent;
  }
}
export default withRouter(ProtectedPath);
