export const maskEmail = email => {
    const [username, domain] = email.split('@');

    if (!domain) {
        return mask(username);
    }

    return `${mask(username)}@${maskDomain(domain)}`;
};

export const maskDomain = domain => {
    const [hostname, ...topLevelDomains] = domain?.split('.') ?? [];

    return [
        mask(hostname),
        ...topLevelDomains
    ].join('.');
};

export const mask = string => {
    if (!string) {
        return '';
    }
    
    const firstLetter = string.charAt(0);
    const rest = string.substr(1);

    return `${firstLetter}${rest.replaceAll(/./g, '*')}`
};
