import * as LDClient from 'launchdarkly-js-client-sdk';
import config from '../config';
import {getSitePath} from '../route/routing';

let ldClient = null;
let ldClientLoadRequest = null;

async function getLDClient() {
  if (!config?.launchDarkly?.clientSideId) {
    return null;
  }

  if (ldClient) {
    return ldClient;
  }

  if (ldClientLoadRequest) {
    return ldClientLoadRequest;
  }

  return ldClientLoadRequest = new Promise((resolve, reject) => {
    const ldUserConfig = {
      anonymous: true,
      custom: {
        tenantSitePath: getSitePath()
      }
    };

    const localLDClient = LDClient.initialize(config.launchDarkly.clientSideId, ldUserConfig, {
      bootstrap: 'localStorage'
    });

    localLDClient.on('ready', () => {
      ldClientLoadRequest = null;
      ldClient = localLDClient;
      resolve(localLDClient);
    });

    localLDClient.on('failed', cause => {
      ldClientLoadRequest = null;
      reject(cause);
    });
  });
}

export class FeatureFlagService {

  async initialize() {
    await getLDClient();
  }

  async get(featureFlag) {
    if (!featureFlag) {
      return featureFlag;
    }

    try {
      const ldClient = await getLDClient();

      if (!ldClient) {
        return featureFlag.defaultValue;
      }

      return ldClient.variation(featureFlag.key, featureFlag.defaultValue);
    } catch (error) {
      console.error(error);
      return featureFlag.defaultValue;
    }
  }

}

export default new FeatureFlagService();