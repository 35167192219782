import { isEmpty } from '../util/object'
import BaseComponent from './BaseComponent';

class FormComponent extends BaseComponent {

    constructor(props, initialState = {}, validators = {}) {
        initialState.formErrors = {};
        super(props, initialState)
        this.validators = validators;
    }

    handleInputChange = ({ target }) => {
        const { name } = target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const error = typeof this.validators[name] === "function" ? this.validators[name](name, value) : null;

        this.setState({
            [name]: value,
            formErrors: { ...this.state.formErrors, [name]: error }
        });
    };

    handleSubmit = async event => {
        event.preventDefault();
        const isValidForm = await this.validateForm()

        if (isValidForm) {
            this.setState({ loading: true });
            this.submit();
        }
    }

    submit = async () => {
        throw new Error("Not implemented yet");
    }

    validateForm = async () => {
        const formErrors = {};
        Object.keys(this.validators).forEach((fieldName) => {
            if (typeof this.validators[fieldName] === "function")
                formErrors[fieldName] = this.validators[fieldName](fieldName, this.state[fieldName]);
        })

        this.setState({ formErrors });
        return isEmpty(formErrors);
    }

    setGlobalError = error =>
        this.setState({
            formErrors: { ...this.state.formErrors, _global: error },
            loading: false
        })

    clearErrors = async callback =>
        this.setState({ formErrors: {} }, callback);
}

export default FormComponent;