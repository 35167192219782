
import Amplify, { Auth } from 'aws-amplify';
import Cookies from 'js-cookie';

const COOKIES_NAME_REGEX = new RegExp(/(cognito|amplify)/, 'i');
const MIGRATION_ERROR_PREFIX = 'UserMigration failed with error ';
const USER_NOT_FOUND_ERROR_TRANSLATION = 'Invalid user'

class CognitoService {
    configure = async (userpoolId, clientId) => {
        Amplify.configure({
            Auth: {
                authenticationFlowType: 'USER_PASSWORD_AUTH',
                userPoolId: userpoolId,
                userPoolWebClientId: clientId,
                mandatorySignIn: false
            }
        });
    };

    signIn = async (username, password, rememberUser = false, client, sitePath) => {
        let user = await Auth.signIn(username, password, {
            client,
            sitePath
        });

        user.loggedIn = false;
        user.rememberUser = rememberUser;
        return user;
    };

    forgotPassword = async username => {
        try {
            return await Auth.forgotPassword(username);
        } catch (err) {
            if (err.code === "UserNotFoundException")
                throw new Error(USER_NOT_FOUND_ERROR_TRANSLATION)

            throw err;
        }
    }

    forgotPasswordSubmit = (username, code, password) =>
        Auth.forgotPasswordSubmit(username, code, password);

    verifyCurrentUserEmail = () =>
        Auth.verifyCurrentUserAttribute('email');

    verifyCurrentUserEmailSubmit = code =>
        Auth.verifyCurrentUserAttributeSubmit('email', code);

    completeNewPassword = (user, password) =>
        Auth.completeNewPassword(user, password);

    currentAuthenticatedUser = params =>
        Auth.currentAuthenticatedUser(params);

    clearAuthenticationData = () => {
        localStorage.clear();
        const cookies = Cookies.get();
        if (cookies) {
            Object.keys(cookies)
                .filter((cookieName) => cookieName.match(COOKIES_NAME_REGEX))
                .forEach((cookieName) => Cookies.remove(cookieName));
        }
    };

    extractErrorMessage = err => {
        if (typeof err === "string")
            return this.translateErrorMessage(err);
        else if (typeof err === "object")
            return this.extractErrorMessage(err.message);
        else {
            console.log(err);
            return 'Unknown error';
        }
    }

    translateErrorMessage = (message) => {
        if (typeof message !== "string")
            return null;

        if (message.toLowerCase().includes('user not found'))
            return USER_NOT_FOUND_ERROR_TRANSLATION;

        return message
            .replace('User is disabled', 'User is inactive')
            .replace(MIGRATION_ERROR_PREFIX, '');
    }
}

export default new CognitoService();