import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { I18n } from 'aws-amplify';
import { FormContainer, FormSection, SectionHeader, FormField, Input, SectionBody, SectionFooter, SectionFooterPrimaryContent, Link, Button } from './Utils';
import LoadingPanel from './LoadingPanel';
import MailOutlineIcon from '../icons/MailOutline';
import { useError } from '../util/hooks';
import { checkInvalidEmail } from '../util/validation';
import authGatewayService from '../services/AuthGatewayService';

export function TenantLookup(props) {
  const { requestState } = props;

  const location = useLocation();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [success, setSucess] = useState(false);

  const [email, setEmail] = useState('');
  const [error, setError] = useError(false);

  const checkEmailError = useCallback((value = email, immediate = true) => {
    const error = checkInvalidEmail(value);
    setError(error, immediate);

    return error;
  }, [email, setError]);

  const handleEmailChange = useCallback(event => {
    const { value } = event.target;
    setEmail(value);
    checkEmailError(value, false);
  }, [setEmail, checkEmailError]);

  const handleGoBackClick = useCallback(() => {
    history.push({
      ...location,
      pathname: '/tenantLookup'
    });
  }, [history, location]);

  const handleSubmit = useCallback(async event => {
    event.preventDefault();

    if (checkEmailError()) {
      return;
    }

    setLoading(true);

    try {
      await authGatewayService.fetchUserTenantsByEmail(email, requestState);
      setSucess(true);
    } catch (error) {
      console.error(error);
      setError(error.message, true);
    } finally {
      setLoading(false);
    }
  }, [requestState, email, checkEmailError, setError, setLoading]);

  const handleTryAgain = useCallback(async event => {
    setSucess(false);
    setEmail('');
  }, [setSucess, setEmail]);

  return (
    <>
      {loading && (
        <LoadingPanel />
      )}

      {!loading && (
        <>
          {success && (
            <FormSection>
              <FormContainer style={{ marginBottom: 0 }}>
                <MailOutlineIcon size={70} />

                <SectionHeader>
                  {I18n.get('Your organization URL is on the way.')}
                </SectionHeader>

                <p>Check the inbox of <b>{email}</b> for your link.</p>

                <Link onClick={handleTryAgain}>
                  {I18n.get('Wrong email address?')}
                </Link>
              </FormContainer>
            </FormSection>
          )}

          {!success && (
            <FormSection>
              <SectionHeader>
                {I18n.get('Enter your email address')}
              </SectionHeader>

              <form
                onSubmit={handleSubmit}
                style={{
                  minHeight: 200,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
              >
                <SectionBody>
                  <FormField error={error}>
                    <Input
                      type="text"
                      name="email"
                      autoComplete="email"
                      autoFocus={true}
                      value={email}
                      onChange={handleEmailChange}
                    />
                  </FormField>
                </SectionBody>

                <SectionFooter>
                  <SectionFooterPrimaryContent>
                    <Link onClick={handleGoBackClick}>
                      {I18n.get('Back')}
                    </Link>
                  </SectionFooterPrimaryContent>

                  <SectionFooterPrimaryContent>
                    <Button type="submit">
                      {I18n.get('Continue')}
                    </Button>
                  </SectionFooterPrimaryContent>
                </SectionFooter>
              </form>
            </FormSection>
          )}
        </>
      )}
    </>
  );
};

TenantLookup.propTypes = {
  requestState: PropTypes.string
};

export default TenantLookup;