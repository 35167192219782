import React from 'react';

class BaseComponent extends React.Component {

    constructor(props, initialState = {}) {
        super(props)
        this.initialState = {
            loading: false,
            ...initialState
        }

        this.state = this.initialState;
    }

    goTo = path =>
        this.props.history.push(path + this.props.location.search);

    goBackToSignIn = () => {
        if (typeof this.props.onUserChange === "function")
            this.props.onUserChange(null, () => this.goTo('/signIn'));
        else
            this.goTo('/signIn');
    }
}

export default BaseComponent;