import React from 'react';
import { I18n } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import LoadingPanel from './LoadingPanel';
import { FormContainer, FormSection, SectionHeader, FormField, InputLabel, Input, Link, SectionBody, SectionFooter, SectionFooterPrimaryContent, Button, Alert, Checkbox } from './Utils';
import FormComponent from './FormComponent';
import CognitoService from '../services/CognitoService';
import { validateNotEmpty } from '../util/validation'
import { enteredHisCredentials, isEmailVerified } from '../util/user';

class SignIn extends FormComponent {

  constructor(props) {
    super(props, initialState, validators);
  }

  componentDidMount() {
    const { user } = this.props;

    if (enteredHisCredentials(user)) {
      this.setState({ loading: true });
      this.handleAuthentication(user);
    }
  }

  submit = async () => {
    try {
      const { client, sitePath } = this.props;
      const { username, password, rememberUser } = this.state;
      let user = await CognitoService.signIn(username, password, rememberUser, client, sitePath);

      this.handleAuthentication(user);
    } catch (err) {
      this.handleError(err);
    }
  };

  handleAuthentication = async user => {
    if (!isEmailVerified(user))
      this.handleEmailVerification(user);
    else if (user.challengeName)
      this.handleChallenge(user);
    else {
      user.loggedIn = true;
      this.props.onUserChange(user, () => this.goTo('/loggedIn'));
    }
  }

  handleEmailVerification = async user =>
    this.props.onUserChange(user, () => this.goTo('/verifyEmail'));

  handleChallenge = async user => {
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      const missingAttributes = user.challengeParam?.requiredAttributes;

      if (missingAttributes?.length)
        this.handleMissingAttributes(missingAttributes);
      else
        this.props.onUserChange(user, () => this.goTo('/requireNewPassword'));

    } else {
      const errorMessage = 'Authentication flow not supported. Contact your administrator';
      this.setGlobalError(errorMessage);
      console.log(`Not supported challenge: ${user.challengeName}`);
    }
  }

  handleMissingAttributes = missingAttributes => {
    const joinedAttributes = missingAttributes.join(', ');
    const errorMessage = `Your account is not available because the following data is missing: ${joinedAttributes}. Ask your administrator to update your account and include the missing data.`;
    this.setGlobalError(errorMessage)
  };

  handleError = err => {
    if (err.code === 'PasswordResetRequiredException') {
      const user = buildExpiredPasswordUser(this.state.username);
      this.props.onUserChange(user, () => this.goTo('/passwordReset'));

    } else {
      const errorMessage = CognitoService.extractErrorMessage(err);
      this.setGlobalError(errorMessage)
    }
  };

  render() {
    const { sitePath } = this.props;
    const { loading, username, password, rememberUser, formErrors } = this.state;

    if (loading)
      return <LoadingPanel />

    return (
      <>
        <FormSection style={{ marginBottom: (!sitePath ? 10 : null) }}>
          <SectionHeader>{I18n.get('Log in to your account')}</SectionHeader>
          <form onSubmit={this.handleSubmit}>
            <SectionBody>
              <FormField error={formErrors.username}>
                <InputLabel>{I18n.get('Username')} *</InputLabel>
                <Input
                  name="username"
                  key="username"
                  autoComplete="username"
                  type="text"
                  value={username}
                  onChange={this.handleInputChange}
                  autoFocus={true}
                />
              </FormField>
              <FormField error={formErrors.password} hint={this.buildPasswordHint()}>
                <InputLabel>{I18n.get('Password')} *</InputLabel>
                <Input
                  name="password"
                  key="password"
                  type="password"
                  value={password}
                  onChange={this.handleInputChange}
                />
              </FormField>
            </SectionBody>

            {formErrors._global && <Alert severity="error" title="Error" description={formErrors._global} />}

            <SectionFooter>
              <SectionFooterPrimaryContent>
                <Checkbox
                  name="rememberUser"
                  label="Keep me logged in"
                  checked={rememberUser}
                  onChange={this.handleInputChange}
                />
              </SectionFooterPrimaryContent>
              <SectionFooterPrimaryContent>
                <Button type="submit" disabled={loading}>
                  {I18n.get('Log In')}
                </Button>
              </SectionFooterPrimaryContent>
            </SectionFooter>
          </form>
        </FormSection>

        {!Boolean(sitePath) && (
          <FormContainer style={{ margin: '10px 0px 50px' }}>
            <Link
              onClick={() => this.goTo('/tenantLookup')}
              style={{ fontWeight: 'bold', fontSize: '1.1em' }}
            >
              {I18n.get('Find my Organization')}
            </Link>
          </FormContainer>
        )}
      </>
    );
  }

  buildPasswordHint = () =>
    <Link onClick={() => this.goTo('/passwordReset')}>{I18n.get('Forgot password?')}</Link>
}

const initialState = {
  username: '',
  password: '',
  rememberUser: false
}

const validators = {
  username: validateNotEmpty,
  password: validateNotEmpty
}

const buildExpiredPasswordUser = username => {
  return {
    username,
    passwordExpired: true
  };
}

export default withRouter(SignIn);
