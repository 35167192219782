import React from 'react'
import LoadingSpinner from './LoadingSpinner';

export default class LoadingPanel extends React.PureComponent {
  render() {
    return (
      <div className="panel-center">
        <LoadingSpinner size={100} />
      </div>
    );
  }
}
