import * as React from 'react';
import { I18n } from 'aws-amplify';
import { FormSection, SectionHeader, SectionBody, Input, SectionFooter, SectionFooterPrimaryContent, Button, Link, Alert, FormField, InputLabel } from './Utils';
import { withRouter } from 'react-router-dom';
import FormComponent from './FormComponent';
import LoadingPanel from './LoadingPanel';
import CognitoService from '../services/CognitoService';
import { validateNotEmpty } from '../util/validation';

class RequireNewPassword extends FormComponent {

  constructor(props) {
    super(props, initialState, validators);
  }

  submit = async () => {
    try {
      const user = await CognitoService.completeNewPassword(this.props.user, this.state.password);

      user.loggedIn = true;
      this.props.onUserChange(user, () => this.goTo('/signIn'));
    } catch (err) {
      const errorMessage = CognitoService.extractErrorMessage(err);
      this.setGlobalError(errorMessage);
    }
  };

  render() {
    const { loading, password, formErrors } = this.state;

    return (
      <>
        {loading && <LoadingPanel />}
        {!loading && (
          <FormSection>
            <form onSubmit={this.handleSubmit}>
              <SectionHeader>{I18n.get('Change Password')}</SectionHeader>
              <SectionBody>
                <FormField error={formErrors.password}>
                  <InputLabel>{I18n.get('New Password')} *</InputLabel>
                  <Input
                    name="password"
                    key="password"
                    type="password"
                    value={password}
                    onChange={this.handleInputChange}
                    autoFocus={true}
                  />
                </FormField>
              </SectionBody>

              {formErrors._global && <Alert severity="error" title="Error" description={formErrors._global} />}

              <SectionFooter>
                <SectionFooterPrimaryContent>
                  <Link onClick={this.goBackToSignIn}>{I18n.get('Back to Log In')}</Link>
                </SectionFooterPrimaryContent>
                <SectionFooterPrimaryContent>
                  <Button type="submit">{I18n.get('Change')}</Button>
                </SectionFooterPrimaryContent>
              </SectionFooter>
            </form>
          </FormSection>
        )}
      </>
    );
  }
}

const initialState = { password: '' }
const validators = { password: validateNotEmpty }

export default withRouter(RequireNewPassword);
