import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { I18n } from 'aws-amplify';
import { FormContainer, FormSection, SectionHeader, FormField, Input, SectionBody, SectionFooter, SectionFooterPrimaryContent, Link, Button, } from './Utils';
import LoadingPanel from './LoadingPanel';
import { useError } from '../util/hooks';
import { getLandingAppDomain } from '../util/vidp';
import authGatewayService from '../services/AuthGatewayService';

const SITE_PATH_URL = `${getLandingAppDomain()}/`;

const VALID_SITE_PATH_REGEX = /^[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?$/;

function checkInvalidSitePath(sitePath) {
  if (!sitePath) {
    return 'Enter your URL.';
  }

  if (!VALID_SITE_PATH_REGEX.test(sitePath)) {
    return 'Enter a valid URL.';
  }

  return false;
}

export function TenantLookup(props) {
  const { requestState } = props;

  const location = useLocation();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [sitePath, setSitePath] = useState('');
  const [error, setError] = useError(false);

  const checkSitePathError = useCallback((value = sitePath, immediate = true) => {
    const error = checkInvalidSitePath(value);
    setError(error, immediate);

    return error;
  }, [sitePath, setError]);

  const handleSitePathChange = useCallback(event => {
    const { value } = event.target;
    setSitePath(value);
    checkSitePathError(value, false);
  }, [setSitePath, checkSitePathError]);

  const handleGoTo = useCallback(path => () => {
    history.push({
      ...location,
      pathname: path
    });
  }, [history, location]);

  const handleSubmit = useCallback(async event => {
    event.preventDefault();

    if (checkSitePathError()) {
      return;
    }

    setLoading(true);

    try {
      await authGatewayService.setTenantSitePath(sitePath, requestState);
    } catch (error) {
      console.error(error);
      setError(error.message, true);
    } finally {
      setLoading(false);
    }
  }, [requestState, sitePath, checkSitePathError, setError, setLoading]);

  return (
    <>
      {loading && (
        <LoadingPanel />
      )}

      {!loading && (
        <>
          <FormSection
            style={{
              marginBottom: 10
            }}
          >
            <SectionHeader>
              {I18n.get('Enter your Vector Solutions URL')}
            </SectionHeader>

            <form
              onSubmit={handleSubmit}
              style={{
                minHeight: 200,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <SectionBody>
                <FormField error={error}>
                  <Input
                    type="text"
                    name="sitePath"
                    autoComplete="organization-site-path"
                    startAddornment={SITE_PATH_URL}
                    autoFocus={true}
                    value={sitePath}
                    onChange={handleSitePathChange}
                  />
                </FormField>
              </SectionBody>

              <SectionFooter>
                <SectionFooterPrimaryContent>
                  <Link onClick={handleGoTo('/signIn')}>
                    {I18n.get('Back')}
                  </Link>
                </SectionFooterPrimaryContent>

                <SectionFooterPrimaryContent>
                  <Button type="submit">
                    {I18n.get('Continue')}
                  </Button>
                </SectionFooterPrimaryContent>
              </SectionFooter>
            </form>
          </FormSection>

          <FormContainer
            style={{
              margin: '10px 0px 50px'
            }}
          >
            <span>
              {I18n.get('Not sure? ')}

              <Link
                onClick={handleGoTo('/emailTenantLookup')}
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.1em'
                }}
              >
                {I18n.get('Find your Vector Solutions organization URL')}
              </Link>
            </span>
          </FormContainer>
        </>
      )}
    </>
  );
};

TenantLookup.propTypes = {
  requestState: PropTypes.string
};

export default TenantLookup;